import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default {
    getIncludeDeleted(organizationId) {
        return axios
            .get(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/billing_groups?ignore_deleted=true`,
                {
                    params: { take: 500 },
                },
            )
            .then((data) => data.data);
    },
    get(organizationId) {
        return axios
            .get(`${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/billing_groups`, {
                params: { take: 500 },
            })
            .then((data) => data.data);
    },

    getById(billingGroupId) {
        return axios
            .get(`${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupId}`)
            .then((data) => data.data);
    },

    create(organizationId, billingGroup) {
        return axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/organizations/${organizationId}/billing_groups`,
                billingGroup,
            )
            .then((data) => data.data);
    },

    update(billingGroupid, billingGroup) {
        return axios
            .patch(`${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupid}`, billingGroup)
            .then((data) => data.data);
    },

    delete(billingGroupId) {
        return axios
            .patch(`${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupId}`, {
                is_deleted: true,
            })
            .then((data) => data.data);
    },

    getUsers(billingGroupId, take, skip) {
        return axios
            .get(`${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupId}/users`, {
                params: { take, skip },
            })
            .then((data) => data.data);
    },

    addUsers(billingGroupId, emails, language) {
        return axios
            .post(`${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupId}/users`, {
                emails,
                language,
            })
            .then((data) => data.data);
    },

    resendInvitationEmail(billingGroupId, userId) {
        return axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupId}/users/${userId}/resend_email`,
            )
            .then((data) => data.data);
    },

    moveUsers(billingGroupId, userIds) {
        return axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupId}/users/move`,
                userIds,
            )
            .then((data) => data.data);
    },

    removeUser(billingGroupId, userId) {
        return axios
            .delete(`${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupId}/users/${userId}`)
            .then((data) => data.data);
    },

    makeAdmin(billingGroupId, userId) {
        return axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupId}/billing_group_admins`,
                { user_id: userId },
            )
            .then((data) => data.data);
    },

    revokeAdmin(billingGroupId, userId) {
        return axios
            .delete(
                `${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupId}/billing_group_admins/${userId}`,
            )
            .then((data) => data.data);
    },

    getHistory(billingGroupId) {
        return axios
            .get(`${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupId}/history`)
            .then((data) => data.data);
    },
    async getExportApiCredentials(billingGroupId) {
        const { data } = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupId}/export_api_credentials`,
        );
        const [userId, userPassword] = window.atob(data.api_key).split(':');
        const url = `${SERVER_URL}/v1/billing_groups/${billingGroupId}/datasets/latest.json`;
        return { userId, userPassword, url };
    },
    async getAdmins(billingGroupId) {
        const { data } = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/client_admin/billing_groups/${billingGroupId}/billing_group_admins`,
        );
        return data;
    },
};
